body {
  margin: 0;
  font-size: small;
  color: #5c5c5c;
  background-color: #f7f7f7;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.results-row {
  margin-bottom:100px;  
}

.challenge-color {
  background:#0d8fb3;  
}



/* HEADER */
.header-item {
  font-weight: bold;
  font-size: 0.8rem;
}

.header-icon {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}

.avatar {
  content: url('./img/restaurant_avatar_60.jpg');
  width: 60px;
}

.logo {
  content: url('./img/challenge_logo.jpg');  
  width: 150px;
}

/* DISCOUNT BAR */

.discount-bar {
  background-color: #cceff7;
  margin-bottom: 35px;
}

.close {
  cursor: pointer;
  right: 5%;
  font-size: medium;
}

/*  SEARCH BAR */

.results-text {
  font-size: small;
  font-weight: bold;
}

.search-bar-item{
    background-color: white;
}

.search-bar-item input{
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 1px;
  border-color: #e6e6e6;
  background-color: white;
}

.search-bar-item input:focus {
  background-color: #f7f7f7; 
  outline: none !important;
}

.search-bar-box{
  width: 150px;
}

.search-bar-item select{
  color: #5c5c5c;
  width: 150px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 1px;
  border-color: #e6e6e6;
  background-color: white;
}

@media (min-width:568px) and (max-width:1068px) {

  .search-bar-box{
    width: 100px;
  }
  
  .search-bar-item select{
    color: #5c5c5c;
    width: 100px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-left-width: 0px;
    border-bottom-width: 1px;
    border-color: #e6e6e6;
    background-color: white;
  }

}

.search-bar-item select:focus {
  background-color: #f7f7f7; 
  outline: none !important;
}

/* RESTAURANTS */

.restaurant-container {
  padding-left: 0px; 
}

.restaurant-info {
  text-align:left;
  left:-15px;
}


@media (max-width:768px) {
  .restaurant-container {
    padding-left: 15px; 
  }
  .restaurant-info {
    text-align:center;
    left:0;
  }
}


.restaurant-name {
  font-weight: bold;
  font-size: medium;
}
.restaurant-location {
  font-weight: bold;
  font-size: small;
  color: #8d5a2e !important;  
}

.restaurant-address {
  font-size: small;
}

.cut-text { 
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
}

.phone-item { 
  font-size: small;
  width: 150px;
  color: white !important;  
}

.location-item { 
  content: url('./img/location.jpg');  
  width: 10px;  
  display: inline;
}
.location-text {  
  display: inline;
}

/* WEBSITE INFO*/
.website-item { 
  font-size: small;
}

.website-reservation-item { 
  font-weight: bold;
}


.vertical-line{
  display: inline-block;
  border-left: 1px solid #ccc;
  height: 15px; 
  transform: translateY(50%);
}

/* PRICE INFO */

.price-img-active{
  content: url('./img/price_active.jpg');
  width: 10px;  
  display: inline;
}

.price-img-inactive{
  content: url('./img/price_inactive.jpg');  
  width: 10px;
  display: inline;
}

/* PAGINATION */
.page-item.active .page-link { 
  background-color: #0d8fb3;
  border-color: #0d8fb3;
}

.pagination-container { 
  background-color: #f7f7f7;
}

/*MAP CONTAINER*/

.map-container {  
  padding-left: 0px;
}

.map-info-text {
  font-weight: bold;
  font-size: small;
}